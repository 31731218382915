import React from 'react';
import guima from './guima.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
          <div className="row border rounded-5 p-3 bg-white shadow box-area">
            <div className="col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box" style={{ background: '#1D1D1D' }}>
              <div className="featured-image mb-3">
                <img src={guima} alt="featured" className="img-fluid" style={{ width: '250px' }} />
              </div>
              <p style={{ fontFamily: 'Poppins, sans-serif, monospace', fontWeight: 600, color: '#F5E450', fontSize: '30px' }}>CECaça 2.0</p>
              <small style={{ fontFamily: 'Poppins, sans-serif, monospace', color: '#F5E450' }}>Site feito para matar pistinha.</small>
            </div>
            <div className="col-md-6 right-box">
              <div className="row align-items-center">
                <div className="header-text mb-4">
                  <h2>Bem vindos,</h2>
                  <p>Me arranja um trampo ai namoral</p>
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control form-control-lg bg-light fs-6" placeholder="Endereço de email" />
                </div>
                <div className="input-group mb-1">
                  <input type="password" className="form-control form-control-lg bg-light fs-6" placeholder="Senha" />
                </div>
                <div className="input-group mb-5 d-flex justify-content-between">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="formCheck" />
                    <label htmlFor="formCheck" className="form-check-label text-secondary"><small>Lembrar de mim</small></label>
                  </div>
                  <div className="forgot">
                    <small><a href="#">Esqueceu caralho?</a></small>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <button className="btn btn-lg btn-dark w-100 fs-6">Login</button>
                </div>
                <div className="input-group mb-3">
                  <button className="btn btn-lg btn-light w-100 fs-6"><img src="images/google.png" alt="google" style={{ width: '20px' }} className="me-2" /><small>Login com o Google</small></button>
                </div>
                <div className="row">
                  <small>Não tem um conta? <a href="#">Registre aqui</a></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
